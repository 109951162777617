<template>
  <div class="warranty-config-list">
    <KTCodePreview v-bind:title="'Danh sách lý do bảo hành'">
      <!-- button create new -->
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <router-link to="/warranty-reason-config/add">
              <button
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i
                  style="font-size: 1rem"
                  class="flaticon2-add-1"
                ></i>Tạo mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <!-- reason configuration table -->
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col
                class="ml-0 pl-0"
                cols="3"
              >
                <Autosuggest
                  :suggestions="filteredInternalCateOptions"
                  :model="selectedInternalCate"
                  :placeholder="'danh mục nội bộ'"
                  :limit="limit"
                  @change="onInputInternalCateChange"
                  @select="onSelectInternalCate($event)"
                />
              </b-col>
              <b-col cols="3">
                <Autosuggest
                  :suggestions="filteredGroupReasonOptions"
                  :model="selectedGroupReason"
                  :placeholder="'nhóm bảo hành'"
                  :limit="limit"
                  @change="onInputGroupReasonChange"
                  @select="onSelected($event)"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Lý do bảo hành"
                  v-model="searchName"
                  single-line
                  size="sm"
                ></b-input>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col
                md="1"
                style="padding-left: 0"
              >
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                >Lọc</b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="6">
            <b-table
              :items="warrantyReasonConfigs"
              :fields="fields"
              bordered
            >
              <template v-slot:cell(name)="row">
                <span class="table-warranty-reason">{{ row.item.name }}</span>
              </template>
              <template v-slot:cell(warrantyReasonGroupName)="row">
                <span class="table-warranty-reason">{{
                  row.item.warrantyReasonGroupName
                }}</span>
              </template>
              <template v-slot:cell(status)="row">
                <span v-if="!row.item.status">
                  <b-badge variant="success">
                    Hoạt động
                  </b-badge>
                </span>
                <span v-else>
                  <b-badge variant="danger">
                    Ngưng hoạt động
                  </b-badge>
                </span>
              </template>
              <template v-slot:cell(actions)="row">
                <b-dropdown
                  size="sm"
                  no-caret
                  right
                >
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editItem(row.item)">
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen icon-color"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </b-dropdown-item>
                  <b-dropdown-item @click="showDeleteAlert(row.item.id)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row cols="4">
          <b-col>
            <p class="mt-3 text-dark font-weight-bolder">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col style="display: grid; justify-content: end;"><b-pagination-nav
              class="custom-pagination"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchMainData"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template> </b-pagination-nav></b-col>
          <b-col> </b-col>
          <b-col> </b-col>
        </b-row>
        <b-col></b-col>
        <b-col></b-col>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<style lang="scss">
.warranty-config-list {
  input.form-control {
    border: 1px solid #ced4da;
  }
  .icon-color {
    color: #464e5f;
  }
  .table-warranty-reason {
    word-break: break-all;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  components: {
    KTCodePreview,
    Autosuggest,
  },
  data() {
    return {
      limit: 10,
      onLoading: false,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      warrantyReasonConfigs: [],
      numberOfPage: null,
      totalPage: '',
      searchName: '',
      searchGroupCate: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          tdClass: 'sttClass',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '12%',
            textAlign: 'center',
          },
        },
        {
          key: 'name',
          label: 'Lý do bảo hành',
          sortable: false,
          thStyle: { color: '#181c32', width: '240px' },
        },
        {
          key: 'warrantyReasonGroupName',
          label: 'Nhóm bảo hành',
          sortable: false,
          thStyle: { color: '#181c32', width: '240px' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      apiParams: {
        page: 1,
        size: 20,
      },
      selectedGroupReason: '',
      selectedGroupReasonId: null,
      groupReasonOptions: [],
      filteredGroupReasonOptions: [],
      selectedInternalCate: '',
      selectedInternalCateId: null,
      internalCateOptions: [],
      filteredInternalCateOptions: [],
    };
  },
  async created() {
    this.fetchMainData();
    this.fetchWarrantyGroupReasons();
    this.getListInternalCate();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lý do bảo hành', route: 'Danh sách lý do bảo hành' },
    ]);
  },
  methods: {
    getListInternalCate: function () {
      const paramQuery = {
        params: { page: 1, limit: 1000, name: '', code: '' },
      };
      ApiService.query('productExternalCategory', paramQuery).then(
        ({ data }) => {
          this.internalCateOptions = [];
          this.numberOfPage = data.data.total_page;
          data.data.list_caterogy.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '-' + element.name;
            } else if (element.level === 2) {
              name = '--' + element.name;
            } else if (element.level === 3) {
              name = '---' + element.name;
            } else if (element.level === 4) {
              name = '----' + element.name;
            } else if (element.level === 5) {
              name = '-----' + element.name;
            } else if (element.level === 6) {
              name = '------' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.internalCateOptions.push(item);
          });
          this.filteredInternalCateOptions = [...this.internalCateOptions];
        },
      );
    },
    onSelectInternalCate(option) {
      this.selectedInternalCateId = option.item.id;
      this.selectedInternalCate = option.item.name;
    },
    onInputInternalCateChange(text) {
      this.selectedExternalCate = text;

      const filteredData = this.internalCateOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredInternalCateOptions = [...filteredData];
    },
    checkFeatureByUser: async function () {
      let result = true;
      await ApiService.setHeader();
      await ApiService.query('bills/check-warranty-feature')
        .then((response) => {
          const { status, message } = response.data;
          if (status !== 1) {
            makeToastFaile(message);
            result = false;
          }
        })
        .catch(() => {
          result = false;
        });

      return result;
    },
    onFilter() {
      this.fetchMainData();
    },
    fetchWarrantyGroupReasons: async function () {
      ApiService.query('warranty-reason-config/group').then((response) => {
        const { data } = response.data;
        data.forEach((element) => {
          const item = {
            id: element.id,
            name: element.name,
          };
          this.groupReasonOptions.push(item);
        });
        this.filteredGroupReasonOptions = [...this.groupReasonOptions];
      });
    },
    onInputGroupReasonChange(text) {
      this.selectedGroupReason = text;
      const filteredData = this.groupReasonOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredGroupReasonOptions = [...filteredData];
    },
    onSelected(option) {
      this.selectedGroupReasonId = option.item.id;
      this.selectedGroupReason = option.item.name;
    },
    fetchMainData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const param = {
        page: this.page,
        pageSize: 10,
        name: this.searchName,
        warrantyReasonGroupId: this.selectedGroupReasonId,
        internalCate: this.selectedInternalCateId,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('warranty-reason-config', paramQuery)
        .then((response) => {
          const dataResponse = response.data.data;
          this.warrantyReasonConfigs = [];
          this.totalPages =
            dataResponse.totalPages > 0 ? dataResponse.totalPages : 1;
          this.totalItems = dataResponse.totalItems;
          this.currentPage = dataResponse.currentPage;
          this.warrantyReasonConfigs = dataResponse.result.map(
            (item, index) => {
              return {
                count:
                  this.currentPage >= 2
                    ? index + 1 + this.currentPage * 10 - 10
                    : index + 1,
                id: item.id,
                name: item.name,
                deleted: item.deleted,
                status: item.status,
                warrantyReasonGroupId: item.warrantyReasonGroupId,
                warrantyReasonGroupName: item.warrantyReasonGroupName,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
              };
            },
          );
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    deleteItem: async function (item) {
      ApiService.patch('warranty-reason-config' + '/' + item)
        .then((data) => {
          const { status, message } = data.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchMainData();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa lý do!',
        text: 'Bạn có chắc muốn xóa lý do này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
        .then((result) => {
          if (result.value) {
            this.deleteItem(item);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          this.makeToastFaile(message);
        });
    },
    editItem: function (item) {
      // update item by warranty reason group id
      this.$router.push({
        path: 'warranty-reason-config/update',
        query: { id: item.warrantyReasonGroupId },
      });
    },
  },
};
</script>

<style lang="scss"></style>
